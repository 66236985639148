import React from 'react'

import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { StaticImage } from 'gatsby-plugin-image'
import KlaviyoForm from '../../components/utilities/klaviyo-form'

import Seo from '../../components/seo'
import Layout from '../../components/layout'

import meatiHattie from '../../images/sms/sxsw-meati-x-hattie.svg'

const SMS = () => {
  return (
    <Layout className="sms-page sms-page__sxsw">
      <Seo title="SXSW Free meati x Hattie B's" noindex />
      <Container fluid className="stack-parent bg-danger p-0">

        <div className="stack-child">
          <Row>
            <Col xs="4" md="7" className="d-flex align-items-center">
              <StaticImage
                src="../../images/sms/sxsw-mobile-left.png"
                quality={95}
                placeholder="none"
                formats={['AUTO', 'WEBP']}
                alt="meati sxsw stickers"
                className="ms-n1 d-md-none"
              />
              <StaticImage
                src="../../images/sms/sxsw-desktop-left.png"
                quality={95}
                placeholder="none"
                formats={['AUTO', 'WEBP']}
                alt="meati sxsw stickers"
                className="ms-n2 d-none d-md-block"
              />
            </Col>
            <Col xs="4" md="5" className="offset-4 offset-md-0 d-flex align-items-center">
              <StaticImage
                src="../../images/sms/sxsw-mobile-right.png"
                quality={95}
                placeholder="none"
                formats={['AUTO', 'WEBP']}
                alt="meati sxsw stickers"
                className="me-n1 d-md-none"
              />
              <StaticImage
                src="../../images/sms/sxsw-desktop-right.png"
                quality={95}
                placeholder="none"
                formats={['AUTO', 'WEBP']}
                alt="meati sxsw stickers"
                className="ms-n2 d-none d-md-block"
              />
            </Col>
          </Row>
        </div>
        <div className="stack-child">
          <Row className="pt-2 pb-4 pb-md-2">
            <Col xs="8" md="5" className="d-flex align-items-center offset-2 offset-md-5">
              <div id="sxswForm">
                <img src={meatiHattie} className="mb-2 d-md-none" id="meatiHattieLogo" />
                <KlaviyoForm code="XGg4Kz" />
              </div>
            </Col>
          </Row>

        </div>
      </Container>
    </Layout>
  )
}

export default SMS
